<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <base-input
      :label="$t('COMMON.ORGANIZATION')"
      :placeholder="$t('COMMON.ORGANIZATION')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <organization-selector
        :organization="taxGroup.organization.id"
        :filterable="true"
        :showAll="false"
        :allowNone="true"
        :disabled="!!taxGroup.id"
        @organizationChanged="
          (organizationId) => {
            taxGroup.organization.id = organizationId;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />

    <base-checkbox
      v-model="taxGroup.active"
      class="mb-3"
      @change="
        () => {
          onFormChanged();
        }
      "
    >
      <span class="form-control-label">{{ $t("TAX_GROUPS.ACTIVE") }}</span>
    </base-checkbox>
    <validation-error :errors="apiValidationErrors.active" />

    <base-input
      :label="`${$t('COMMON.NAME')} (*)`"
      :placeholder="$t('COMMON.NAME')"
      v-model="taxGroup.name"
      @change="onFormChanged"
    />
    <validation-error :errors="apiValidationErrors.name" />

    <base-input
      :label="`${$t('TAX_GROUPS.COUNTRY_CODE')} (*)`"
      :placeholder="$t('TAX_GROUPS.COUNTRY_CODE')"
      v-model="taxGroup.country_code"
      @change="onFormChanged"
    />
    <validation-error :errors="apiValidationErrors.country_code" />

    <base-input :label="$t('COMMON.TAXES')" :placeholder="$t('COMMON.TAXES')">
      <tax-group-form-taxes-selector
        :filterOrganization="taxGroup.organization.id"
        :taxes="taxGroup.taxes"
        @taxesChanged="
          (taxes) => {
            taxGroup.taxes = taxes;
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.excerpt" />

    <base-input
      :label="$t('COMMON.EXCERPT')"
      :placeholder="$t('COMMON.EXCERPT')"
    >
      <html-editor v-model="taxGroup.excerpt" @change="onFormChanged()">
      </html-editor>
    </base-input>
    <validation-error :errors="apiValidationErrors.excerpt" />

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          taxGroup.id
            ? $t("TAX_GROUPS.EDIT_TAX_GROUP")
            : $t("TAX_GROUPS.ADD_TAX_GROUP")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import models from "@/constants/models";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import TaxGroupFormTaxesSelector from "./TaxGroupFormTaxesSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    ValidationError,
    OrganizationSelector,
    TaxGroupFormTaxesSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["taxGroupData", "formErrors", "loading"],

  data() {
    let taxGroupData = { ...this.taxGroupData };
    taxGroupData = this.$fillUserOrganizationData(taxGroupData);
    return {
      taxGroup: taxGroupData,
      models: models,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let taxGroupData = cloneDeep(this.taxGroup);
      taxGroupData = this.$fillUserOrganizationData(taxGroupData);
      this.$emit("taxGroupSubmitted", taxGroupData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    taxGroupData(taxGroupData) {
      if (taxGroupData) {
        this.taxGroup = { ...this.taxGroup, ...cloneDeep(taxGroupData) };
        if (!this.taxGroup.organization) {
          this.taxGroup.organization = {
            type: "organizations",
            id: null,
          };
        }
      }
    },
  },
};
</script>
