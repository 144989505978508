<template>
  <div class="form-group tax-group-taxes-selector">
    <div class="row mb-4">
      <tax-selector
        class="col-10"
        :filterOrganization="filterOrganization"
        :showAll="false"
        @taxChanged="
          (taxId, tax) => {
            selectedTax = tax;
          }
        "
      />
      <base-button
        type="button"
        class="btn btn-sm col-2"
        style="border: 1px solid #ccc"
        @click="addTax"
      >
        <i class="fas fa-plus fa-2x" />
      </base-button>
    </div>

    <h3 class="col-12 mt-5">{{ $t("TAXES.TAX_TYPE_SALES") }}</h3>
    <div
      v-for="(taxdata, key) in salesTaxes"
      :key="`${taxdata.id}`"
      class="row mb-2"
    >
      <div class="col-1 mx-0 pr-0">
        <base-button
          v-if="key > 0"
          type="button"
          class="px-1 mx-0 my-0 btn"
          style="border: 1px solid #ccc"
          @click.prevent="() => moveUpSalesTaxes(key)"
        >
          <i class="fas fa-arrow-up" />
        </base-button>
        <base-button
          v-if="key < salesTaxes.length - 1"
          type="button"
          class="px-1 mx-0 my-0 btn"
          style="border: 1px solid #ccc"
          @click.prevent="() => moveDownSalesTaxes(key)"
        >
          <i class="fas fa-arrow-down" />
        </base-button>
      </div>
      <div
        class="col-10 pl-0 ml-0 px-2 py-2"
        style="border: 1px solid #ccc; border-radius: 0.25rem"
      >
        {{ taxdata.name }}
      </div>
      <div class="col-1 remove-taxes-button">
        <base-button
          type="button"
          class="form-control btn btn-xs col-12 btn-danger"
          style="border: 1px solid #ccc"
          @click.prevent="() => removeSalesTax(key)"
        >
          <i class="fas fa-times" />
        </base-button>
      </div>
    </div>

    <h3 class="col-12 mt-5">{{ $t("TAXES.TAX_TYPE_SALES") }}</h3>
    <div
      v-for="(tax, key) in purchasesTaxes"
      :key="`${tax.id}_${key}`"
      class="row mb-2"
    >
      <div class="col-1 mx-0 pr-0">
        <base-button
          v-if="key > 0"
          type="button"
          class="px-1 mx-0 my-0 btn"
          style="border: 1px solid #ccc"
          @click.prevent="() => moveUpPurchasesTaxes(key)"
        >
          <i class="fas fa-arrow-up" />
        </base-button>
        <base-button
          v-if="key < purchasesTaxes.length - 1"
          type="button"
          class="px-1 mx-0 my-0 btn"
          style="border: 1px solid #ccc"
          @click.prevent="() => moveDownPurchasesTaxes(key)"
        >
          <i class="fas fa-arrow-down" />
        </base-button>
      </div>
      <div
        class="col-10 pl-0 ml-0 px-2 py-2"
        style="border: 1px solid #ccc; border-radius: 0.25rem"
      >
        {{ tax.name }}
      </div>
      <div class="col-1 remove-taxes-button">
        <base-button
          type="button"
          class="form-control btn btn-xs col-12 btn-danger"
          style="border: 1px solid #ccc"
          @click.prevent="() => removePurchasesTax(key)"
        >
          <i class="fas fa-times" />
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { TAX_TYPE_SALES, TAX_TYPE_PURCHASES } from "@/constants/taxes";
import { Option, Select } from "element-ui";
import TaxSelector from "@/components/TaxSelector.vue";

export default {
  components: {
    TaxSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    taxes: {
      type: Array,
      default: () => {
        return [];
      },
      description: "Taxes array",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
  },
  data() {
    return {
      selectedTax: null,
      salesTaxes: cloneDeep(
        this.taxes.filter((item) => item.tax_type == TAX_TYPE_SALES)
      ),
      purchasesTaxes: cloneDeep(
        this.taxes.filter((item) => item.tax_type == TAX_TYPE_PURCHASES)
      ),
    };
  },

  setup() {},

  created() {},

  methods: {
    addTax() {
      if (!this.selectedTax) {
        return;
      }
      if (this.selectedTax.tax_type == TAX_TYPE_SALES) {
        const tax = this.salesTaxes.find(
          (item) => item.id == this.selectedTax.id
        );
        if (!tax) {
          this.salesTaxes.push(cloneDeep(this.selectedTax));
        }
      } else if (this.selectedTax.tax_type == TAX_TYPE_PURCHASES) {
        const tax = this.purchasesTaxes.find(
          (item) => item.id == this.selectedTax.id
        );
        if (!tax) {
          this.purchasesTaxes.push(cloneDeep(this.selectedTax));
        }
      }
      this.selectedTax = null;
      this.taxesChanged();
    },

    taxesChanged() {
      this.$emit("taxesChanged", [
        ...cloneDeep(this.salesTaxes),
        ...cloneDeep(this.purchasesTaxes),
      ]);
    },

    removePurchasesTax(key) {
      this.purchasesTaxes = this.purchasesTaxes.filter((_, idx) => idx !== key);
      this.taxesChanged();
    },

    removeSalesTax(key) {
      this.salesTaxes = this.salesTaxes.filter((_, idx) => idx !== key);
      this.taxesChanged();
    },

    moveUpSalesTaxes(key) {
      if (key > 0 && key < this.salesTaxes.length) {
        [this.salesTaxes[key], this.salesTaxes[key - 1]] = [
          this.salesTaxes[key - 1],
          this.salesTaxes[key],
        ];
        this.salesTaxes = [...this.salesTaxes];
        this.taxesChanged();
      }
    },

    moveDownSalesTaxes(key) {
      if (key >= 0 && key < this.salesTaxes.length - 1) {
        [this.salesTaxes[key], this.salesTaxes[key + 1]] = [
          this.salesTaxes[key + 1],
          this.salesTaxes[key],
        ];
        this.salesTaxes = [...this.salesTaxes];
        this.taxesChanged();
      }
    },

    moveUpPurchasesTaxes(key) {
      if (key > 0 && key < this.purchasesTaxes.length) {
        [this.purchasesTaxes[key], this.purchasesTaxes[key - 1]] = [
          this.purchasesTaxes[key - 1],
          this.purchasesTaxes[key],
        ];
        this.purchasesTaxes = [...this.purchasesTaxes];
        this.taxesChanged();
      }
    },

    moveDownPurchasesTaxes(key) {
      if (key >= 0 && key < this.purchasesTaxes.length - 1) {
        [this.purchasesTaxes[key], this.purchasesTaxes[key + 1]] = [
          this.purchasesTaxes[key + 1],
          this.purchasesTaxes[key],
        ];
        this.purchasesTaxes = [...this.purchasesTaxes];
        this.taxesChanged();
      }
    },
  },

  watch: {
    taxes(taxes) {
      this.salesTaxes = cloneDeep(
        taxes.filter((item) => item.tax_type == TAX_TYPE_SALES)
      );
      this.purchasesTaxes = cloneDeep(
        taxes.filter((item) => item.tax_type == TAX_TYPE_PURCHASES)
      );
    },
  },
};
</script>
