export default {
  type: "tax-groups",
  name: null,
  excerpt: "",
  country_code: "",
  taxes: [],
  active: true,
  relationshipNames: ["organization", "taxes"],
  organization: {
    type: "organizations",
    id: null,
  },
};
