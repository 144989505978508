var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('base-input',{attrs:{"label":_vm.$t('COMMON.ORGANIZATION'),"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.taxGroup.organization.id,"filterable":true,"showAll":false,"allowNone":true,"disabled":!!_vm.taxGroup.id},on:{"organizationChanged":(organizationId) => {
          _vm.taxGroup.organization.id = organizationId;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}}),_c('base-checkbox',{staticClass:"mb-3",on:{"change":() => {
        _vm.onFormChanged();
      }},model:{value:(_vm.taxGroup.active),callback:function ($$v) {_vm.$set(_vm.taxGroup, "active", $$v)},expression:"taxGroup.active"}},[_c('span',{staticClass:"form-control-label"},[_vm._v(_vm._s(_vm.$t("TAX_GROUPS.ACTIVE")))])]),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.active}}),_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.NAME')} (*)`,"placeholder":_vm.$t('COMMON.NAME')},on:{"change":_vm.onFormChanged},model:{value:(_vm.taxGroup.name),callback:function ($$v) {_vm.$set(_vm.taxGroup, "name", $$v)},expression:"taxGroup.name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.name}}),_c('base-input',{attrs:{"label":`${_vm.$t('TAX_GROUPS.COUNTRY_CODE')} (*)`,"placeholder":_vm.$t('TAX_GROUPS.COUNTRY_CODE')},on:{"change":_vm.onFormChanged},model:{value:(_vm.taxGroup.country_code),callback:function ($$v) {_vm.$set(_vm.taxGroup, "country_code", $$v)},expression:"taxGroup.country_code"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.country_code}}),_c('base-input',{attrs:{"label":_vm.$t('COMMON.TAXES'),"placeholder":_vm.$t('COMMON.TAXES')}},[_c('tax-group-form-taxes-selector',{attrs:{"filterOrganization":_vm.taxGroup.organization.id,"taxes":_vm.taxGroup.taxes},on:{"taxesChanged":(taxes) => {
          _vm.taxGroup.taxes = taxes;
        }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}}),_c('base-input',{attrs:{"label":_vm.$t('COMMON.EXCERPT'),"placeholder":_vm.$t('COMMON.EXCERPT')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.taxGroup.excerpt),callback:function ($$v) {_vm.$set(_vm.taxGroup, "excerpt", $$v)},expression:"taxGroup.excerpt"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}}),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.taxGroup.id ? _vm.$t("TAX_GROUPS.EDIT_TAX_GROUP") : _vm.$t("TAX_GROUPS.ADD_TAX_GROUP"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }